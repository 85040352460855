.App {
  text-align: center;
  display:flex;
  flex-direction:column;
  flex-grow: 1;
}
.div1{
  background-color: white;
  padding-bottom: 5rem;
  display: flex;
}
#headerH{
  font-size:3rem;
  color : #003b67;
}
#anond1{
  margin-left: 5em;
  margin-top: 1.5rem;
}
.brand-label{ 
  margin-left:0.3rem;
  font-size :1.2rem;
  color: #003b67;
  font-weight: bold;

 }
#anond{
    margin:5rem;
    display: flex;
    flex-direction: column;
    margin-bottom: 0rem;
}

.desc-label{
  text-align: left;
  font-size: 1.2rem;
  color:#8f9aa0;
}
.mini-header{
  font-size:2.5rem;
}
.App-logo {
 
}
.c-label{
  font-size:1rem;
  display: inline-block;
  animation: blink-caret 4.0s step-end infinite;
  border-right:0.15em solid black;
  color:#8f9aa0;
  margin-left: 0px;

}
.sec-div{
  margin-top: 4rem;
  margin-right: 3rem;
  margin-left: 3rem;
  padding: 2rem;
  border-radius: 0.5rem;
   min-width: 13rem;
}
#sec1-div{
  margin:0px;
  border-radius: 0rem;
  padding: 6rem;

}
.info-div{
    padding: 5rem;
  }
.want-button{

    background-color: #e5584e;
    background-color: #fda712;
    font-size: 0.9rem;
    border: none;
    padding: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    font-weight: bold;
    border-radius: 0.3rem;
    color: white;
    margin-top: 5rem;
}
/*@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}*/

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes typing {
  0% { width: 0 }
  50% { width: 100% }
}

@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: black; }
}

@media only screen and (max-device-width:760px){
  #headerH{
    font-size:3rem;
    text-align: start;
  }
  #anond{
    margin:0;
    margin-bottom:0rem;

  }
  #anond1 {
    margin-top: 2rem;
    margin-left: 2rem;
  }
  .c-label{
    font-size:1.2rem;
    text-align: start;
    margin-top: 2rem;
  }
  .brand-label {
    font-size: 1.5rem;
    
  }
  
  .div1{
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-direction: row;
    min-height:75vh;
    margin:2rem;
    padding: 0;
    padding-bottom: 0rem;
  }
  .info-div{
    flex-direction: column;
    padding: 2rem;
  }
  .mini-header{
    font-size:2rem;
  }
  .desc-label{
    font-size:1.2rem;
  }
  .sec-div{
    margin-top:2rem;
    margin-right: 0rem;
    margin-left: 0rem;
  }
  #sec1-div{
    margin:0px;
    border-radius: 0rem;
    padding: 2rem;

  }

  .lady{
    display: none;
  }
  .want-button{
    font-size: 1rem;
    padding: 0.8rem;
    border-radius: 0.5rem;
    color: white;
    flex-grow:1;
    margin-bottom: 0rem;
    margin-top: 3rem;
  }

  .App-logo{
    height:8vmin;
  }


}

@media only screen and (min-device-width:768px) and (max-device-width:1024px) and (orientation:portrait){
    
    #headerH{
    font-size:4rem;
    text-align: start;
  }
  #anond{
    margin:0;
    margin-bottom:5rem;

  }
  #anond1 {
    margin-top: 2rem;
  }
  .c-label{
    font-size:2rem;
    text-align: start;
    margin-top: 2rem;
  }
  .brand-label {
    font-size: 2rem;
    
  }
  
  .div1{
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-direction: row;
    min-height:90vh;
    margin:6rem;
    padding: 0;
    padding-bottom: 0rem;
  }
  .info-div{
    flex-direction: column;
  }
  .mini-header{
    font-size:2.5rem;
  }
  .desc-label{
    font-size:1.8rem;
  }
  .sec-div{
    margin-top:2rem;
    margin-right: 0rem;
  }
  .lady{
    display: none;
  }
  .want-button{
    font-size: 1.5rem;
    padding: 1.2rem;
    border-radius: 0.5rem;
    color: white;
    flex-grow:1;
    margin-bottom: 7rem;
  }

  .App-logo{
    height:8vmin;
  }

}



@media only screen and (min-device-width:1366px) and (min-device-height:1024px){
  #headerH{
  font-size:3.5rem;
    text-align: start;
  }
  #anond{
    flex-basis: 0;
    flex-grow: 1;
    margin:0;

  }
  .brand-label {
    margin-left: 0.3rem;
    font-size: 2rem;
    
  }
  .c-label{
    font-size:1.5rem;
    text-align: start;
    margin-top: 2rem;
  }
  #anond1 {
    margin-left: 6em;
    margin-top: 4rem;
  }
  #anond2{
    flex-basis: 0;
    flex-grow:1;
  }
  
  .div1{
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-direction: row;
    min-height:70vh;
    margin:6rem;
    padding: 0;
    padding-bottom: 0rem;
  }
  .mini-header{
    font-size:2rem;
  }
  .desc-label{
    font-size:1.2rem;
  }
  .sec-div{
    margin-top:4rem;
    margin-right: 2rem;
    margin-left:2rem;
    padding: 1.2rem;
  }

  .want-button{
    font-size: 1rem;
    padding: 0.8rem;
    border-radius: 0.5rem;
    color: white;
    flex-grow:1;
    margin-bottom: 10rem;
  }

  .App-logo{
    height:unset;
  }
}

@media only screen and (min-device-width:1440px) and (min-device-height:900px){

  #headerH{
    font-size:5rem;
    text-align: start;
  }
  #anond{
    flex-basis: 0;
    flex-grow: 1;
    margin: 0;
  }
  
  .brand-label {
    margin-left: 0.3rem;
    font-size: 2rem;
    
  }
#anond1 {
    margin-left: 6em;
    margin-top: 4rem;
}
  #anond2{
    flex-basis: 0;
    flex-grow:1;
  }
  .c-label{
    font-size:2rem;
    text-align: start;
    margin-top: 2rem;
  }
  .div1{
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-direction: row;
    min-height:70vh;
    margin:6rem;
    padding: 0;
    padding-bottom: 0rem;
  }
  .mini-header{
    font-size:3rem;
  }
  .desc-label{
    font-size:2rem;
  }
  .sec-div{
    margin-top:4rem;
    margin-right: 2rem;
    margin-left:2rem;
    min-width: 15rem;
  }

  .want-button{
    font-size: 1.5rem;
    padding: 1.2rem;
    border-radius: 0.5rem;
    color: white;
    margin-bottom: 10rem;
  }

  
}